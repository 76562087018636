import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title">Food 4U AS</h2>
              <h5 className="description">
                Orgnr: 914 424 070
                <br /><br />
                Storehagen 7<br />
                6800 FØRDE
              </h5>

              <h2 className="title">Kundeservice:</h2>
              <p>Best Stasjon AS</p>
              <p>Tlf. +47 22 55 18 90 | post@beststasjon.no</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
